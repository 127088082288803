import React from 'react';
import { Container, Typography } from '@mui/material';
import './Footer.css';
import Logo from '../../../assets/images/logo.png';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container className="footer-container">
        <img src={Logo} alt="School Logo" className="footer-logo" />
        <Typography variant="body2" className="footer-text">
          © {currentYear} Eduwaves. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
