import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';

// Lazy load components
const HomePage = lazy(() => import('./pages/home/HomePage'));
const AuthPage = lazy(() => import('./pages/auth/AuthPage'));
const UsersPage = lazy(() => import('./pages/users/UsersPage'));
const EnrollmentRequestsPage = lazy(() => import('./pages/enrollmentRequests/EnrollmentRequestsPage'));
const CourseAnnouncementPage = lazy(() => import('./pages/courseAnnouncement/CourseAnnouncementPage'));
const ResearchPage = lazy(() => import('./pages/research/ResearchPage'));
const UniversityRequestsPage = lazy(() => import('./pages/universityRequests/UniversityRequestsPage'));

const Loading = lazy(() => import('./components/common/loading/Loading'));

function useTokenChangeEffect() {
  const [token, setToken] = useState(() => {
    const savedToken = localStorage.getItem('token');
    return savedToken ? String(savedToken) : '';
  });

  useEffect(() => {
    // Save the token to localStorage when it changes and is a valid string
    if (typeof token === 'string' && token.trim() !== '') {
      console.log('Setting token in localStorage:', token);
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    // Listen for changes to the localStorage from other browsing contexts
    const handleStorageChange = (e) => {
      if (e.key === 'token') {
        setToken(e.newValue || '');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return [token, setToken];
}


function App() {
  const [token, setToken] = useTokenChangeEffect();
  const [isLoggedIn, setIsLoggedIn] = useState(!!token); // Initialize based on token

  useEffect(() => {
    setIsLoggedIn(!!token); // Update isLoggedIn whenever token changes
  }, [token]);

  return (
    <Suspense fallback={<div className="container">Loading...</div>}>
      <Router>
        <div className="app-container">
          {isLoggedIn && <Header />}
          <div className="main-content">
            <Routes>
              <Route path="/" element={isLoggedIn ? <HomePage /> : <Navigate to="/auth" />} />
              <Route path="/auth" element={!isLoggedIn ? <AuthPage setToken={setToken} setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/" />} />
              <Route path="/users" element={isLoggedIn ? <UsersPage /> : <Navigate to="/auth" />} />
              <Route path="/enrollment-requests" element={isLoggedIn ? <EnrollmentRequestsPage /> : <Navigate to="/auth" />} />
              <Route path="/course-announcement" element={isLoggedIn ? <CourseAnnouncementPage /> : <Navigate to="/auth" />} />
              <Route path="/research" element={isLoggedIn ? <ResearchPage /> : <Navigate to="/auth" />} />
              <Route path="/university-requests" element={isLoggedIn ? <UniversityRequestsPage /> : <Navigate to="/auth" />} />
              <Route path="/loading" element={<Loading />} />
            </Routes>
          </div>
          {isLoggedIn && <Footer />}
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
