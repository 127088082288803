import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Button, Menu, MenuItem, Divider, Typography, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';

import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import Logo from '../../../assets/images/logo.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { NavLink } from 'react-router-dom';

const Header = ({ forceScrolled }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrolled, setScrolled] = useState(forceScrolled);
  const location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload(); // Refresh the app to trigger a redirect to /auth
  };

  const isLoggedIn = localStorage.getItem('token');

  const handleMenuOpen = (event) => {
    if (window.innerWidth <= 768) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    if (!forceScrolled) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [forceScrolled]);


  // Helper function to check if the link is active
  const isActive = (path) => location.pathname === path;

  const drawer = (
    <div>
      <img src={Logo} alt="Logo" className="logo m-3" />
      {
        isLoggedIn? 
        <>
          <List>
          <ListItem button component={Link} to="/" className={isActive('/') ? 'active' : ''}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button component={Link} to="/users" className={isActive('/users') ? 'active' : ''}>
            <ListItemText primary="Users" />
          </ListItem>
          <ListItem button component={Link} to="/enrollment-requests" className={isActive('/enrollment-requests') ? 'active' : ''}>
            <ListItemText primary="Enroll Requests" />
          </ListItem>
          <ListItem button component={Link} to="/course-announcement" className={isActive('/course-announcement') ? 'active' : ''}>
            <ListItemText primary="Course Announcement" />
          </ListItem>
          <ListItem button component={Link} to="/research" className={isActive('/research') ? 'active' : ''}>
            <ListItemText primary="Research & Projects" />
          </ListItem>
          <ListItem button component={Link} to="/university-requests" className={isActive('/university-requests') ? 'active' : ''}>
            <ListItemText primary="University Requests" />
          </ListItem>
          {
            isLoggedIn ? 
            <>
              <ListItem button onClick={handleLogout}>
                <LogoutIcon className="colored-icon"/>
                <ListItemText primary="Log Out" />
              </ListItem>
            </>
            :
            <></>
          }
      </List>
        </>
        :
        <></>
      }
      
    </div>
  );
  

  return (
    <AppBar position="fixed" className={`header ${true ? 'header-scrolled' : ''}`}>
      <Toolbar className="toolbar">
        <div className="left-section">
          <img src={Logo} alt="Logo" className={`logo ${true ? '' : 'logo-hidden'}`} />
          <div className="nav-links">
            {
              isLoggedIn? 
              <>
                <Button
              component={NavLink}
              to="/"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Home
            </Button>
            <Button
              component={NavLink}
              to="/users"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Users
            </Button>
            
            <Button
              component={NavLink}
              to="/enrollment-requests"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Enroll Requests
            </Button>
            <Button
              component={NavLink}
              to="/course-announcement"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Course Announcement
            </Button>
            <Button
              component={NavLink}
              to="/research"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              Research & Projects
            </Button>
            <Button
              component={NavLink}
              to="/university-requests"
              color="inherit"
              className={({ isActive }) => (isActive ? 'active' : '')}
            >
              University Requests
            </Button>
              </>
              :
              <></>
            }
            
          </div>
        </div>
        {
          isLoggedIn? 
          <>
            <Tooltip title="Log Out" placement="bottom" className="logout-tooltip">
              <IconButton color="inherit" onClick={handleLogout} className="logout-button">
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </>
          :
          <></>
        }
        <IconButton
          edge="start"
          className="menu-button"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className="drawer"
        >
          {drawer}
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
